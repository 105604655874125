import { httpClient } from '@/utils/http-client'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { logRocketIdentify } from '@/services/marketing'
import { AutoFraudFlagReasons, systemFraudFlagsHelper } from '@/utils/systemFraudFlagsHelper'
import _ from 'lodash'

const getApplicantReturning = async (returnToken) => {
    const response = await httpClient.get('/remoteNotarization/applicantReturning', {
        params: {
            returnToken,
        },
    })
    saveApplicantSessionResponse(response)
    logRocketIdentify()
    return response
}

// Keep in sync with enum in aven_backend/src/entity/home.ts
export enum ResidenceType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}

// Keep in sync with consts in aven_backend/src/manager/LegalDocumentManager.ts
export enum LegalDocumentTypes {
    hudGovDoc = 'HomeOwnershipCounselingServices',
    creditScoreDisclosure = 'CreditScoreDisclosure',
    shortFormDeedOfTrust = 'ShortFormDeedOfTrust',
    longFormDeedOfTrust = 'LongFormDeedOfTrust',
    certificationOfTrust = 'CertificationOfTrust',
    helocDeedOfTrust = 'HelocDeedOfTrust',
    lastTransferDocument = 'LastTransferDocument',
    accountAgreement = 'AccountAgreement',
    noticeOfRightToCancel = 'NoticeOfRightToCancel',
    adverseActionNotice = 'AdverseActionNotice',
    fictitiousDeedOfTrust = 'FictitiousDeedOfTrust',
    earlyHELOCDisclosure = 'EarlyHELOCDisclosure',
    pricingAndTerms = 'PricingAndTerms',
    propertyValuation = 'PropertyValuation',
    appraisalWaiver = 'AppraisalWaiver',
}

// Keep in sync with remoteNotarizationController.ts's NextSigningAction.
const NextSigningAction = {
    currentApplicantShouldSign: 'currentApplicantShouldSign',
    nextApplicantShouldSign: 'nextApplicantShouldSign',
    allApplicantsAlreadySigned: 'allApplicantsAlreadySigned',
}

const getNextSigningAction = async () => {
    return await httpClient.get('/remoteNotarization/getNextSigningAction')
}

const saveApplicantSessionResponse = (response) => {
    if (response.data.payload && response.data.payload.jwt) {
        const data = response.data

        logger.info(`Saving applicant session response - applicant id: ${data.payload.applicantId} / loan application id: ${data.payload.loanApplicationId}`)

        appSessionStorage.setItem(localStorageKey.jwtTokens, JSON.stringify(data.payload.jwt))
        appSessionStorage.setItem(localStorageKey.creditOffer, JSON.stringify(data.payload.creditOffer))

        // These are to be used ONLY FOR LOGGING PURPOSES
        appSessionStorage.setItem(localStorageKey.applicantId, data.payload.applicantId.toString())
        appSessionStorage.setItem(localStorageKey.loanApplicationId, data.payload.loanApplicationId.toString())
        appSessionStorage.setItem(localStorageKey.experimentName, data.payload.experimentName)
        appSessionStorage.setItem(localStorageKey.applicantNotaryAssignmentId, data.payload.applicantNotaryAssignmentId.toString())
        appSessionStorage.setItem(localStorageKey.experimentOverrides, JSON.stringify(response.data.payload.experimentOverrides || {}))
    }
}

const postUpdateApplicantFields = async (postBody) => {
    return await httpClient.post('/updateApplicantFields', postBody)
}

const postUpdateApplicantAddress = async (payload) => {
    return await httpClient.post('/updateApplicantAddress', payload)
}

const getLegalDocument = async (docType) => {
    return await httpClient.get('/legal/document', {
        responseType: 'blob',
        params: {
            docType,
        },
    })
}

const getAvailableLastTransferDocNames = async (applicantNotaryAssignmentId) => {
    return await httpClient.get('/remoteNotarization/notary/lastTransferDocNames', {
        params: {
            applicantNotaryAssignmentId,
        },
    })
}

const startFetchTransferDocumentForNotary = async (transferDocName, applicantNotaryAssignmentId) => {
    return await httpClient.get('/remoteNotarization/notary/startFetchTransferDocument', {
        params: {
            transferDocName,
            applicantNotaryAssignmentId,
        },
    })
}

const getLegalDocumentForNotary = async (docType, loanApplicationId, applicantId) => {
    return await httpClient.get('/legal/documentForNotary', {
        responseType: 'blob',
        params: {
            docType,
            loanApplicationId,
            applicantId,
        },
    })
}

const getDocusignLegalDownload = async (docType) => {
    return await httpClient.get('/remoteNotarization/docuSign/document', {
        responseType: 'blob',
        params: {
            docType: docType,
        },
    })
}

const sendUserFeedbackEmail = async (feedbackText, applicantId) => {
    return await httpClient.post('/userFeedback/email', {
        feedbackText,
        applicantId,
    })
}

const updateApplicantDetails = async (applicantId, applicantNotaryAssignmentId, applicantFirstName, applicantLastName, applicantDateOfBirth, notaryVerified, reason) => {
    return await httpClient.post('/remoteNotarization/notary/updateApplicantDetails', {
        applicantId,
        applicantNotaryAssignmentId,
        applicantFirstName: applicantFirstName ?? undefined,
        applicantLastName: applicantLastName ?? undefined,
        applicantDateOfBirth: applicantDateOfBirth ?? undefined,
        notaryVerified,
        reason,
    })
}

const postUpdateFraudFlag = async (loanApplicationId, fraudFlag) => {
    if (!loanApplicationId) {
        throw new TypeError('Invalid loanApplicationId parameter')
    }

    const systemFraudFlags = systemFraudFlagsHelper.getFlags()

    if (!fraudFlag && !systemFraudFlags) {
        throw new TypeError('Invalid payload parameter')
    }

    const allUniqueFraudReasonEnums = _.uniq([...(fraudFlag?.reasonEnums || []), ..._.flatMap(systemFraudFlags, (flag) => flag.reasonEnums)])

    const allUniqueFraudReasons = _.uniq([fraudFlag?.reasons, ...systemFraudFlags.map((flag) => flag.reasons)]).filter(Boolean)

    const response = await httpClient.post('/notary/flagFraudAccount', {
        loanApplicationId,
        notaryId: `${appSessionStorage.getItem(localStorageKey.notaryId)}`,
        notaryName: appSessionStorage.getItem(localStorageKey.notaryName),
        notarySessionId: appSessionStorage.getItem(localStorageKey.sessionId),
        status: true,
        reasonEnums: allUniqueFraudReasonEnums,
        reasons: allUniqueFraudReasons,
    })

    // Do this after the await to avoid setting this flag if the post throws
    appSessionStorage.setItem(localStorageKey.alreadySubmittedFraudFlag, 'true')

    return response
}

const postIsAccountFlaggedAsFraud = async (loanApplicationId) => {
    if (!loanApplicationId) {
        throw new TypeError('Invalid loanApplicationId parameter')
    }

    return await httpClient.post('/notary/isAccountFlaggedAsFraudulent', { loanApplicationId })
}

const getStatePageModules = async (screenName) => {
    const postBody = {
        screenName: screenName,
    }

    return httpClient.post('/stateModules', postBody)
}

export {
    postUpdateApplicantAddress,
    startFetchTransferDocumentForNotary,
    getAvailableLastTransferDocNames,
    getApplicantReturning,
    getNextSigningAction,
    postUpdateApplicantFields,
    getLegalDocument,
    getDocusignLegalDownload,
    getLegalDocumentForNotary,
    sendUserFeedbackEmail,
    updateApplicantDetails,
    NextSigningAction,
    postUpdateFraudFlag,
    postIsAccountFlaggedAsFraud,
    getStatePageModules,
}
