import { logger } from '@/utils/logger'
import throttle from 'lodash/throttle'

export const mixins = {
    methods: {
        throttleLogging: throttle((inputValue, logPrefix = '') => {
            if (inputValue) {
                logger.info(`${logPrefix}: ${inputValue}`)
            }
        }, 1000),
    },
}
