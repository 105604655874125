import { appSessionStorage, localStorageKey } from '@/utils/storage'
import _ from 'lodash'

const SYSTEM_FRAUD_FLAGS_KEY = localStorageKey.systemFraudFlags

// Keep in sync with FraudFlagReasons aven_backend/src/entity/fraudFlag.ts
export enum FraudFlagReasons {
    prompting = 'prompting',
    languageBarriers = 'languageBarriers',
    DLAddressMismatch = 'DLAddressMismatch',
    notInHome = 'notInHome',
    legalPropertyDescription = 'legalPropertyDescription',
    suspicious = 'suspicious',
    other = 'other',
}

// Keep in sync with FraudFlagReasons aven_backend/src/entity/fraudFlag.ts
export enum AutoFraudFlagReasons {
    errorFetchingPropertyDescription = 'errorFetchingPropertyDescription',
    inSessionPersonaVerificationNeedsReview = 'inSessionPersonaVerificationNeedsReview',
    preSessionPersonaVerificationNeedsReview = 'preSessionPersonaVerificationNeedsReview',
    NOOSecondaryResidenceType = 'NOOSecondaryResidenceType',
}

interface FraudFlag {
    reasonEnums: Array<FraudFlagReasons | AutoFraudFlagReasons>
    reasons: string
}

const getFlags = (): FraudFlag[] => {
    const currentSystemFraudFlagsJson = appSessionStorage.getItem(SYSTEM_FRAUD_FLAGS_KEY) || '[]'
    const currentSystemFraudFlags: FraudFlag[] = JSON.parse(currentSystemFraudFlagsJson) as FraudFlag[]
    if (!Array.isArray(currentSystemFraudFlags)) {
        throw new TypeError(`Invalid data stored in ${SYSTEM_FRAUD_FLAGS_KEY}, expected an Array, found: ${currentSystemFraudFlagsJson}`)
    }

    return currentSystemFraudFlags
}

export const systemFraudFlagsHelper = {
    addFlag: (fraudFlag: FraudFlag): FraudFlag[] => {
        const currentSystemFraudFlags = getFlags()
        // use uniq to avoid adding duplicate flags
        const currentSystemFraudFlagsUnique = _.uniq([fraudFlag, ...(currentSystemFraudFlags || [])].map((fraud_flag) => JSON.stringify(fraud_flag))).map(
            (string_flag) => JSON.parse(string_flag) as FraudFlag
        )
        appSessionStorage.setItem(SYSTEM_FRAUD_FLAGS_KEY, JSON.stringify(Array.from(currentSystemFraudFlagsUnique)))
        return currentSystemFraudFlags
    },
    getFlags,
}
