import { ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import { mixins } from '../utils/mixins'

export const formInputMixin = {
    mixins: [mixins],
    props: {
        name: { type: String, required: true },
        inputType: { type: String, default: 'text' },
        value: { type: String, default: null },
        validation: { type: String, default: null },
        label: { type: String, default: null },
        formClasses: { type: String, default: null },
        placeholder: { type: String, default: null },
        focusPlaceholder: { type: String, default: null },
        autocomplete: { type: String, default: null },
        autofocus: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        filter: {
            type: String,
            validator: function (value) {
                return ['uppercase', 'lowercase'].indexOf(value) !== -1
            },
        },
        inputmode: {
            type: String,
            default: 'text',
        },
        validationMode: {
            type: String,
            default: 'lazy',
            validator: function (value) {
                return ['eager', 'aggressive', 'passive', 'lazy'].indexOf(value) !== -1
            },
        },
    },
    components: {
        'validation-provider': ValidationProvider,
    },
    data() {
        return {
            dynamicPlaceholder: this.placeholder,
            formId: '',
        }
    },
    mounted() {
        // Shows a different placeholder on focus
        if (this.focusPlaceholder) {
            const _this = this
            document.getElementById(this.name).onfocus = function () {
                _this.dynamicPlaceholder = _this.focusPlaceholder
            }

            document.getElementById(this.name).onblur = function () {
                _this.dynamicPlaceholder = _this.placeholder
            }
        }

        if (this.autofocus) {
            this.setFocus()
        }

        const formEl = document.getElementsByTagName('form')?.[0]
        this.formId = formEl.id || ''
    },
    methods: {
        onInput(eventTarget) {
            let filteredValue = eventTarget.value
            if (this.filter === 'uppercase') {
                filteredValue = filteredValue.toUpperCase()
            }
            if (this.filter === 'lowercase') {
                filteredValue = filteredValue.toLowerCase()
            }
            this.throttleLogging(`${eventTarget.name}: ${filteredValue}`, this.formId)
            this.$emit('input', filteredValue)
        },
        blur() {
            this.$refs.formFieldInput.blur()
        },
        setFocus() {
            this.$refs.formFieldInput.focus()
        },
        applyError(errorMessage) {
            this.$refs.provider.applyResult({
                errors: [errorMessage], // array of string errors
                valid: false, // boolean state
                failedRules: {}, // should be empty since this is a manual error.
            })
        },
    },
}

export const formInputCleaveMixin = {
    props: {
        name: { type: String, required: true },
        value: { type: String, default: null },
        validation: { type: String, default: null },
        label: { type: String, default: null },
        formClasses: { type: String, default: null },
        placeholder: { type: String, default: null },
        focusPlaceholder: { type: String, default: null },
        autocomplete: { type: String, default: null },
        autofocus: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        validationMode: {
            type: String,
            default: 'lazy',
            validator: function (value) {
                return ['eager', 'aggressive', 'passive', 'lazy'].indexOf(value) !== -1
            },
        },
    },
    components: {
        cleave: Cleave,
        'validation-provider': ValidationProvider,
    },
    created() {
        this.cleaveValue = this.value
    },
    mounted() {
        if (this.autofocus) {
            this.setFocus()
        }
    },
    data() {
        return {
            cleaveValue: null,
            dynamicPlaceholder: this.placeholder,
        }
    },
    watch: {
        cleaveValue: function (value) {
            this.$emit('input', value)
        },
    },
    methods: {
        setFocus() {
            this.$nextTick(() => this.$refs.formFieldInput.$el.focus())
        },
    },
}
